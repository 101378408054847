import React from 'react';
import { graphql } from "gatsby"
import moment from "moment"
import Layout from "../components/layout"
import { color } from "../components/defaults/styles/theme"
import mq from "../components/defaults/styles/mediaquerys"
import {
  wrapper,
  IconButtonProhibOverflow,
} from "../components/defaults/styles/elements"
import { css } from "@emotion/core"
import PressFooter from "../components/pressfooter"
import Link from '../components/link';
import ProfileYoutube from '../components/main/profile-youtube';
import {
  TitleHeader,
  PeopleDisclaimer,
} from "../components/defaults/styles/elements"
import { Instagram, Twitter, Linkedin } from "../components/defaults/assets/svg/social.js"
import Globe from "../components/defaults/assets/svg/globe.svg"
import Video from "../components/defaults/assets/svg/video.svg"
import RearviewInline, { ifRearview } from "../components/main/rearviewInline"
import Mail from "../components/defaults/assets/svg/mail.svg"
import Calendar from "../components/defaults/assets/svg/calendar.svg"
import YoutubeIcon from "../components/defaults/assets/svg/play.svg"
import AgendaEl from '../components/main/agenda-el';

import { ExpCard, ExpCard__Square, ExpCard__Media } from '../components/main/expcard'; 


const ShortFacts = ({user}) => {
  let company = "";
  let company_link = "";

  if ( user.partner_company && user.partner_company.length >0 ) {
    company = user.partner_company[0].data.name
    company_link =
      "/partners/" +
      decodeURIComponent(
        user.partner_company[0].data.name
          .replace(/\./g, "")
          .replace(/&/g, "")
          .replace(/\s/g, "")
          .replace(/-/g, "")
          .replace(/é/g, "e")
      )
  } else if ( user.startup_company && user.startup_company.length >0 ) {
    company = user.startup_company[0].data.startup
    company_link = "/all-access/startups/" + user.startup_company[0].id
  } else if ( user.custom_company ) {
    company = user.custom_company
    company_link = ""
  } else {  }

  const shortfacts_copy = css`
    font-size: .85em;
    margin: 0;
    color: ${color.main_dark};
    font-weight: 500;
  `

  const shortfacts_link = [shortfacts_copy, css`
    display: inline-block;
    background: ${color.accent_light};
    padding: .5em 2em;
    border-radius: 5px;
    text-decoration: none;
    margin: .5em 0;
    min-width: 20%;
    text-align: center;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid ${color.main_dark};
      border-radius: 5px;
      opacity: .2;
    }
  `]

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2em 0 1em;
        ${mq[2]} {
          align-items: flex-start;
          margin: 0;
        }
      `}
    >
      <div
        css={css`
          margin-bottom: 1.5em;
          display: flex;
          flex-wrap: wrap;
          max-width: 40rem;
          justify-content: center;
          ${mq[2]} {
            justify-content: flex-start;
          }
          p {
            margin: 0;
            background: ${color.main_dark};
            padding: 0.3em 1em;
            border-radius: 3px;
            color: white;
            font-size: 0.7em;
            font-weight: 600;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            margin: 0.15em 0.3em;
            &:first-of-type {
              margin-left: 0;
            }
          }
        `}
      >
        <p>{user.group}</p>
        {user.people_speaker &&
        user.people_speaker.length > 0 &&
        user.group !== "External Speaker" ? (
          <p>Speaker</p>
        ) : (
          ""
        )}
        {user.people_host && user.people_host.length > 0 ? <p>Host</p> : ""}
        {user.testimonial_speaker && user.testimonial_speaker.length > 0 ? (
          <p>Testimonial</p>
        ) : (
          ""
        )}
      </div>

      <h2
        css={css`
          font-size: 2em;
          margin: 0;
          text-align: center;
          margin-bottom: 0.5em;
          ${mq[2]} {
            text-align: left;
          }
        `}
      >
        { user && user.firstname && user.surename ?
          user.firstname + " " + user.surename 
          : user && user.surename ?
          user.surename
          : user && user.firstname ?
           user.firstname : '' }
      </h2>
      <p
        css={[
          shortfacts_copy,
          css`
            text-align: center;
            ${mq[2]} {
              text-align: left;
            }
          `,
        ]}
      >
        {user.profession}
      </p>
      {company && company_link ? (
        <Link css={[shortfacts_link]} to={company_link}>
          {company ? company : ""}
        </Link>
      ) : company ? (
        <div
          css={[
            shortfacts_link,
            css`
              p {
                margin-bottom: 0;
              }
            `,
          ]}
        >
          <p>{company ? company : ""}</p>
        </div>
      ) : "" }
    </div>
  )
}

const Devider = ({Included}) => {
  if (Included < 1) {
    return null
  }
  return (
    <li css={css`
      margin-bottom: .5em;
    `}>
      <div css={css`
        height: .5em;
        width: 100%;
      `} />
    </li>

  )
}


export const linkHandler = (url, internal, type) => {
  const twitterHandler = /^@/g
  if (type === "twitter_url" && twitterHandler.test(url)) {
    return "https://twitter.com/" + url
  }

  let url_mod = url.trim()
  // const intern = /^\/(?!\/)/.test(url)
  const extern = /^(https?:\/\/)/.test(url_mod)

  if ( internal !== true && extern !== true && type === "email" ) {
    return "mailto:" + url_mod
  } else if ( internal !== true && extern !== true ) {
    return "https://" + url_mod
  } else {
    return url_mod
  }

}

export const handleDisplayLinks = (url) => {
  const extern = /^(https?:\/\/)/
  const trailingslash = /\/$/

  return url.trim().replace(extern, "").replace(trailingslash, "")

}


const SectionHeading = ({title, subtitle}) => {
  return (
    <>
      <h3
        css={css`
          margin: 0;
          font-size: 1em;
        `}
      >
        {title}
      </h3>
      <p
        css={css`
          margin: 0;
          font-size: 0.75em;
        `}
      >
        {subtitle}
      </p>
    </>
  )
}



const Links = ({user}) => {
  const link_data = [
    {
      email: {
        icon: Mail,
        internal: false
      },
      linkedin_url: {
        title: "Linkedin",
        icon: Linkedin,
        internal: false
      },
      website: {
        icon: Globe,
        internal: false
      },
      twitter_url: {
        title: "Twitter",
        icon: Twitter,
        internal: false
      },
      instagram: {
        title: "Instagram",
        icon: Instagram,
        internal: false
      },
    },
  ]

  let Icon = "";
  let Included = 0;
  const resetIncluded = () => {
    Included = 0;
  }

  const NumberOfLinks = () => {
    let counter = 0;
    Object.keys(link_data[0]).forEach((key, index) => {
      if (
        (key !== "email" && user[key] && user[key] !== undefined) ||
        (key === "email" && user[key] && user[key] !== undefined && user.mail_public === true)
      ) {
        counter++
      }
    })
    return counter;
  }
  
  return (
    <div
      css={css`
        padding-top: 3em;
        ${mq[2]} {
          padding-top: 4em;
        }
      `}
    >
      
      {NumberOfLinks() > 0 ? (
        <>
          <SectionHeading
            title="Link List"
            subtitle="In case you‘d like to get in touch."
          />
          <ul
            css={css`
              list-style: none;
              margin: 1.2em 0;
              display: flex;
              flex-direction: column;
              align-items: stretch;
              ${mq[2]} {
                margin: 2.6em 0;
                align-items: stretch; /* flex-start looks fine as well */ 
              }
            `}
          >
            {Object.keys(link_data[0]).map((key, index) => (
              <React.Fragment key={index}>
                {(user[key] && key !== "email") ||
                (user[key] && key === "email" && user.mail_public === true)
                  ? ((Icon = link_data[0][key]["icon"]),
                    Included++,
                    (
                      <li
                        key={index}
                        css={css`
                          margin-bottom: 0.5em;
                          max-width: 100%;
                        `}
                      >
                        <IconButtonProhibOverflow
                          url={linkHandler(
                            user[key],
                            link_data[0][key]["internal"],
                            key
                          )}
                          icon={<Icon />}
                          name={
                            link_data[0][key]["title"]
                              ? link_data[0][key]["title"]
                              : key === "email"
                              ? user[key].toLowerCase()
                              : handleDisplayLinks(user[key])
                          }
                          css={css`
                            display: flex;
                            align-items: center;
                            &:before {
                              background: ${color.main_dark};
                            }
                            p {
                              flex: 0 1 100%;
                            }
                            svg {
                              fill: ${[
                                "instagram",
                                "twitter_url",
                                "linkedin_url",
                              ].includes(key)
                                ? "white"
                                : "none"};
                              height: 1em;
                              flex: 1 0 auto;
                            }
                          `}
                        />
                      </li>
                    ))
                  : ""}

                {["linkedin_url", "website", "instagram"].includes(key)
                  ? [<Devider Included={Included} key={index} />, resetIncluded()]
                  : ""}
              </React.Fragment>
            ))}
          </ul>
        </>
      ) : '' }

    </div>
  )
}

const OnStage = ({user}) => {
  let agendaArray = []
  let agendaArraySorted = []
  let agendaDates = []
  let agendas = []

  if (user.people_speaker && user.people_host) {
    agendaArray = user.people_speaker.concat(user.people_host)
  } else if (user.people_speaker) {
    agendaArray = user.people_speaker
  } else if (user.people_host) {
    agendaArray = user.people_host
  }

  if (agendaArray.length > 1) {
    agendaArraySorted = agendaArray.sort((a, b) => {
      return (
        new Date(a.data.agenda_start_time) - new Date(b.data.agenda_start_time)
      )
    })
  } else {
    agendaArraySorted = agendaArray
  }

  agendaArraySorted.forEach(el => {
    let date = el.data.agenda_start_time.substr(0, 10)
    if (agendaDates.indexOf(date) === -1) {
      agendaDates.push(date)
    }
  })

  agendaDates.forEach((event_date, i) => {
    // let elem = new Object()
    let elem = {}
    elem["date"] = event_date
    elem["data"] = []
    agendaArraySorted.forEach((el, j) => {
      let date = el.data.agenda_start_time.substr(0, 10)
      if (event_date === date) {
        // elem[j] = el
        elem["data"].push(el)
      }
    })
    agendas.push(elem)
  })
  
  return (
    <div css={[css`
      margin-bottom: 5em;
    `]}>
      <TitleHeader
        transparent
        inline
        icon={<Calendar />}
        title={user.name + " on air"}
        subtitle="<p>A sneak peak of the agenda. Find out more on <a href='/agenda/day1'>the comprehensive overview</a>.</p>"
        css={css`
          padding-top: 0;
          h2 {
            font-size: 1em;
          }
          a {
            color: ${color.main_dark};
          }
        `}
      />
      <div>
        {agendas.map((group, index) => (
          <React.Fragment key={index}>
            <div
              css={css`
                margin: 3em 0 1em;
                position: relative;
                line-height: 1em;
                &:first-of-type {
                  margin-top: 1em;
                }
                &:before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 0;
                  background: ${color.main_dark};
                  height: 1px;
                  width: 100%;
                }
              `}
            >
              <p
                css={css`
                  font-weight: 700;
                  font-size: 0.85em;
                  margin: 0;
                  display: inline;
                  background: ${color.main_light};
                  position: relative;
                  line-height: 1em;
                  padding-right: 1em;
                `}
              >
                {moment(group.date).format("MMMM Do, YYYY – dddd")}
              </p>
            </div>

            {group.data.map((el, index) => (
              <AgendaEl
                narrow
                key={index}
                title={el.data.agenda_title}
                start={moment(el.data.agenda_start_time).format("HH:mm")}
                start_utc={moment(el.data.agenda_start_time)
                  .utc()
                  .format("h:mm A")}
                duration={new Date(el.data.agenda_duration * 1000)
                  .toISOString()
                  .substr(12, 4)}
                tags={el.data.agenda_tags}
                speakers={el.data.agenda_speaker}
                hosts={el.data.agenda_host}
                teaser={el.data.agenda_teaser}
                description={
                  el.data.agenda_description
                    ? el.data.agenda_description.childMarkdownRemark.html
                    : ""
                }
                css={css`
                  h3 {
                    font-size: 0.9em;
                  }
                  time {
                    line-height: 1em;
                    & + span {
                      margin-top: 0;
                    }
                  }
                  :not(:last-of-type) {
                    margin-bottom: 1em;
                  }
                `}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}




const Description = ({user}) => {
  // console.log(user.testimonial_speaker[0].data.testimonial_id)
  
  return (
    <div
      css={css`
        padding-top: 2em;
        ${mq[2]} {
          padding-top: 4em;
        }
      `}
    >
      {user.text ? (
        <>
          <div>
            <SectionHeading
              title="Description | Additional Information"
              subtitle="you name it – we put it up"
            />
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: user.text.childMarkdownRemark.html,
            }}
            css={css`
              margin-top: 2em;
              margin-bottom: 5em;
              font-size: 0.85em;
              max-width: 45em;
              p,
              a {
                color: ${color.main_dark};
              }
              ${mq[2]} {
                font-size: 1em;
              }
            `}
          />
        </>
      ) : (
        ""
      )}

      {(user.people_host &&
        user.people_host !== undefined &&
        user.people_host.length > 0) ||
      (user.people_speaker &&
        user.people_speaker !== undefined &&
        user.people_speaker.length > 0) ? (
        <OnStage user={user} />
      ) : (
        ""
      )}

      {user.testimonial_speaker &&
      user.testimonial_speaker !== undefined &&
      user.testimonial_speaker.length > 0 ? (
        <ProfileYoutube
          videoId={user.testimonial_speaker[0].data.testimonial_id}
          title={
            user.testimonial_speaker[0].data.name === "Plug and Play"
              ? "Welcoming Words by " + user.name
              : "Corporate Testimonial by " + user.name
          }
          icon={<YoutubeIcon />}
          inline
          peopleProfile
        />
      ) : (
        ""
      )}

      {user.rearview &&
      user.rearview.length > 0 &&
      ifRearview(user.rearview) ? (
        <div>
          <TitleHeader
            title={`EXPO 8 Rearview & ` + user.name}
            inline
            transparent
            subtitle={`<p>This section only covers clips where ${user.name} participated in. For a comprehensive overview of all videos please head over to <a href="/rearview">the rearview section</a>.</p>`}
            icon={<Video />}
            css={css`
              padding-top: 0em;
              padding-bottom: 1em;
            `}
          />
          <RearviewInline data={user.rearview} peopleProfile />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

const GridWrapperStyle = [
  wrapper,
  css`
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative;
    ${mq[2]} {
      display: grid;
      grid-template-columns: 18em 1fr;
      grid-template-rows: auto;
      gap: 0px 5em;
    }
  `,
]

const colBackgroundStyle = css`
  background: ${color.main_light};
`


const PeopleProfile = ({data}) => {

  const user = data.airtable.data
  return (
    <Layout>
      <section
        css={css`
          padding-top: 7em;
        `}
      >
        <div
          css={[
            colBackgroundStyle,
            css`
              ${mq[2]} {
                position: relative;
                &:before {
                  content: "";
                  top: 0;
                  left: 0;
                  background: white;
                  position: absolute;
                  width: 100%;
                  height: calc(100% - 2em);
                }
              }
            `,
          ]}
        >
          <div css={GridWrapperStyle}>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                &:before {
                  content: "";
                  top: 0;
                  left: -1em;
                  background: white;
                  position: absolute;
                  width: calc(100% + 2em);
                  height: 60%;
                }
                ${mq[2]} {
                  &:before {
                    content: none;
                  }
                }
              `}
            >
              <ExpCard
                css={[
                  css`
                    max-width: 18em;
                    width: 100%;
                    grid-area: profileImage;
                  `,
                ]}
              >
                <ExpCard__Square>
                  <ExpCard__Media img={user.image.localFiles} />
                </ExpCard__Square>
              </ExpCard>
            </div>
            <ShortFacts user={user} />
          </div>
        </div>

        <div css={[colBackgroundStyle, css``]}>
          <div css={GridWrapperStyle}>
            <Links user={user} />

            <Description user={user} />
          </div>
          <div css={[wrapper]}>
            <PeopleDisclaimer
              css={css`
                text-align: center;
                margin: ${user.rearview ? "10em" : "15em"} auto 0;
                padding-left: 1em;
                padding-right: 1em;
              `}
            />
          </div>
        </div>
      </section>
      <PressFooter theme={color.main_light} />
    </Layout>
  )
}

export default PeopleProfile;



export const query = graphql`
         query profile($airtable_id: String) {
           airtable(id: { eq: $airtable_id }) {
             data {
               name
               firstname
               group
               image {
                 localFiles {
                   childImageSharp {
                     fluid {
                       ...GatsbyImageSharpFluid_withWebp_tracedSVG
                     }
                   }
                 }
               }
               instagram
               profession
               linkedin_url
               twitter_url
               website
               text {
                 childMarkdownRemark {
                   html
                 }
               }
               mail_public
               email
               custom_company
               startup_company {
                 id
                 data {
                   startup
                 }
               }
               surename
               partner_company {
                 data {
                   name
                 }
               }
               testimonial_speaker {
                 data {
                   testimonial_id
                 }
               }
               rearview {
                 recordId
                 data {
                   sort
                   publish
                   rearview_tags
                   rearview_thumbnail {
                     localFiles {
                       childImageSharp {
                         fluid {
                           ...GatsbyImageSharpFluid_withWebp_tracedSVG
                         }
                       }
                     }
                   }
                   rearview_description {
                     childMarkdownRemark {
                       excerpt(format: PLAIN, pruneLength: 240)
                     }
                   }
                   rearview_release
                   rearview_title
                   rearview_partners {
                     data {
                       name
                     }
                   }
                   rearview_startups {
                     data {
                       startup
                     }
                   }
                   rearview_agenda {
                     data {
                       agenda_start_time
                     }
                   }
                 }
               }
               people_host {
                 id
                 data {
                   agenda_description {
                     childMarkdownRemark {
                       html
                     }
                   }
                   agenda_start_time
                   agenda_tags
                   agenda_title
                   agenda_duration
                   agenda_teaser
                   agenda_host {
                     id
                     data {
                       name
                       profession
                       partner_company {
                         data {
                           name
                         }
                       }
                       startup_company {
                         data {
                           startup
                         }
                       }
                       custom_company
                     }
                   }
                   agenda_speaker {
                     id
                     data {
                       name
                       profession
                       partner_company {
                         data {
                           name
                         }
                       }
                       startup_company {
                         data {
                           startup
                         }
                       }
                       custom_company
                     }
                   }
                 }
               }
               people_speaker {
                 data {
                   agenda_description {
                     childMarkdownRemark {
                       html
                     }
                   }
                   agenda_start_time
                   agenda_tags
                   agenda_title
                   agenda_duration
                   agenda_teaser
                   agenda_host {
                     id
                     data {
                       name
                       profession
                       partner_company {
                         data {
                           name
                         }
                       }
                       startup_company {
                         data {
                           startup
                         }
                       }
                       custom_company
                     }
                   }
                   agenda_speaker {
                     id
                     data {
                       name
                       profession
                       partner_company {
                         data {
                           name
                         }
                       }
                       startup_company {
                         data {
                           startup
                         }
                       }
                       custom_company
                     }
                   }
                 }
               }
             }
           }
         }
       `