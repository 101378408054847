import React from 'react';
import { RvCardList, RvHandler, RvCardListItem } from '../rearview_components';
import { css } from '@emotion/core';


export const ifRearview = (data) => {
  return data.some((node) => {
    return node.data.publish === true
  })
};


const RearviewInline = ({data, peopleProfile}) => {
  
  return (
    <RvCardList
      css={[
        css`
          padding-top: 0;
        `,
      ]}
    >
      <RvHandler data={data} peopleProfile={peopleProfile} />
      <RvCardListItem placeholder />
      <RvCardListItem placeholder />
      <RvCardListItem placeholder />
    </RvCardList>
  )
}

export default RearviewInline;